<template>
    <div class="personList">
        <div class="flex-row storeItem" v-for="(item, index) in dataArr" :key="index">
            <div class="title"><span class="tip1">核销人手机号：</span>{{ item.mobile }}
            </div>
            <div class="deleteBtn" @click="deleteBtnClick(item)">删除</div>
        </div>
        <div class="addBtnBox">
            <div class="addBtn" @click="addCouponClick">添加核销人</div>
        </div>

    </div>
</template>
<script>
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { Notify } from "vant";
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    data() {
        return {
            dataArr: [],
            quarmas: {
                pageNum: 1,
                pageSize: 100,
                adcId: null
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            total: 0,

        };
    },
    created() {
        this.quarmas.adcId = this.$route.query.adcId
    },
    mounted() {
        this.init();
    },
    methods: {

        init() {

            this.$http.getAdcUserList(this.quarmas).then((res) => {
                if (res.code == 200) {
                    this.dataArr = res.data
                }
            });
        },

        deleteBtnClick(item1) {
            console.log('参数=', item1)
            Dialog.confirm({
                message: '确定删除该核销人吗？',
            })
            .then(() => {
                    console.log('关闭')
                this.requestDeletePerson(item1)
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
            
        },
        requestDeletePerson(item) {
            var param = { ...item }
            this.$http.deleteAdcUser(param).then((res) => {
                if (res.code == 200) {
                    this.init()
                    return
                }
                Notify({ type: "primary", message: res.msg });
            });
        },
        addCouponClick() {
            this.$router.push('/storePersonAdd?storeAdcId=' + this.quarmas.adcId)

        }


    },
};
</script>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.personList {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #f8f8f8;

    .storeItem {
        margin: 0 auto;
        // margin-top: 24px;
        width: 100vw;
        background: white;
        // border-radius: 8px;
        padding: 20px 13px;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(238, 238, 238);
        justify-content: space-between;

        .tip1 {
            font-size: 14px;
            color: rgba(33, 33, 33, 1);
            line-height: 20px;
            font-weight: 450;
        }

        .title {
            font-size: 14px;
            line-height: 20px;
        
            
        }

        .deleteBtn{
            font-size: 13px;
            height: 20px;
            line-height: 20px;
            color: rgba(21, 123, 250, 1);
            width: 60px;
            text-align: right ;
        }

    }

}



.addBtnBox {
    position: fixed;
    width: 100%;
    bottom: 0px;
    bottom: env(safe-area-inset-bottom);
    left: 0px;

    .addBtn {
        width: 90%;
        height: 45px;
        border-radius: 8px;
        background-color: rgba(21, 123, 250, 1);
        margin-left: 5%;
        bottom: 0;
        color: white;
        font-size: 15px;
        text-align: center;
        line-height: 45px;
        margin-bottom: 20px;

        // padding-bottom: ;

    }
}
</style>